import orderBy from "lodash-es/orderBy";
import cloneDeep from "lodash-es/cloneDeep";
import type {
  FormEntry,
  IAccount,
  IAccountingTransaction,
  ReadAccrualInvoice,
  ReadFormattedAccountingTransaction,
  UpdateAccountingTransaction,
  VatType,
} from "@silexpert/core";
import {
  AccountType,
  Nature,
  ProcessingState,
  RoleAllowedToLogInAsClient,
  Vat,
} from "@silexpert/core";
import type { AnnotationError } from "~/@types/localTypes/transaction";
import { AnnotationErrorType } from "~/@types/localTypes/transaction";
import { apiErrorToString } from "~/utils/error";
import type { Loadable } from "~/@types/localTypes/utils";

type TransactionType = ReadFormattedAccountingTransaction | ReadAccrualInvoice;

export function useAnnotationComposable(transaction: TransactionType) {
  const societyStore = useSocietyStore();
  const bankStore = useBankStore();
  const categoryStore = useCategoryStore();
  const thirdPartyStore = useThirdPartiesStore();
  const salesStore = useSalesStore();
  const immoStore = useImmobilizationStore();
  const usersStore = useUsersStore();
  const unlockedStore = useUnlockedStore();
  const purchasesStore = usePurchasesStore();
  const annotateStore = useAnnotateStore();
  const commonStore = useCommonStore();
  // const { hasOneOfTheseRoles } = useRolesComposable();
  const { isPourcentage } = useBrandsComposable();

  const primaryColors = getPrimaryColors();

  const isLoading = ref(false);

  const isAsset = transaction.isAsset ?? false;
  const isProcessedIDocus =
    isPourcentage() &&
    !isDefined(transaction.dateLocked) &&
    transaction.processingState === ProcessingState.SEND_IDOCUS;
  const isAnnotatedAutomatically =
    isDefined(transaction.idRule) || transaction.isAlreadyProcessByOcr || isProcessedIDocus;
  const isCredit = Boolean(
    ((transaction.amount ?? 0) > 0 && !transaction.isAsset) ||
      ((transaction.amount ?? 0) < 0 && transaction.isAsset),
  );
  const isDebit = !isCredit;
  const isLocked = (): boolean =>
    isDefined(transaction.exported) ||
    (isDefined(transaction.dateVerified) &&
      !useRolesComposable().hasOneOfTheseRoles(RoleAllowedToLogInAsClient));
  const isKilometricAllowance: boolean =
    isDefined(transaction.kilometers) && transaction.kilometers > 0;
  const isSplitted = Boolean(transaction.entries && transaction.entries?.length > 1);
  const hasCommentary: boolean = isDefined(transaction.comment) && transaction.comment.length > 0;

  const isAnImmobilizationAccount = (entry: FormEntry | null) => {
    const immobilizationCategories = ref(categoryStore.immobilizationCategories);
    return (entry?.idAccount &&
      immobilizationCategories.value.map((i) => i.id).includes(entry.idAccount)) as boolean;
  };

  // Vat
  const vats = (): VatType[] => {
    return orderBy(
      Object.values(Vat).filter((v) => {
        if ((transaction.amount ?? 0) < 0 && v.id === Vat.MARGE.id) return false;
        if (
          ((transaction.entries?.length ?? 0) > 1 ||
            transaction.entries?.[0]?.isVatImportation ||
            transaction.entries?.[0]?.isVatIntracom) &&
          v.id === Vat.MANUAL.id
        )
          return false;

        return !v.isVatIntracom && !v.isVatImportation;
      }),
      ["value"],
      ["desc"],
    );
  };
  const vat = Object.values(Vat).find((v) => v.id === transaction?.entries?.[0]?.vat) ?? null;

  // Category
  const categories = (): IAccount[] => {
    const categories = ref(categoryStore.categories);
    const immobilizationCategories = ref(categoryStore.immobilizationCategories);

    return categories.value.filter((category) => {
      if (immobilizationCategories.value.map((a) => a.id).includes(category.id)) return false;
      if (category.deletedAt || category.isHidden) return false;
      if (societyStore.isCashBased && category.isOnlyEnga) return false;
      if (!societyStore.isCashBased && category.isOnlyTreso) return false;
      if (
        Array.isArray(category?.accountsSocieties) &&
        category?.accountsSocieties?.find((account) => account.isHidden)
      )
        return false;
      if (
        category.number?.toString().startsWith("455") ||
        category.number?.toString().startsWith("467")
      )
        return false;
      if (category.idNature === Nature.CREDIT && isDebit) return false;
      if (category.idNature === Nature.DEBIT && isCredit) return false;

      return true;
    });
  };
  const category = (): IAccount | null => {
    const idAccount = transaction?.entries?.[0]?.idAccount ?? null;

    return idAccount
      ? (categoryStore.categories.find((category) => category.id === idAccount) ?? null)
      : null;
  };
  const categoryMetaData = (): { color: string; icon: string | undefined } => {
    if (isSplitted)
      return {
        color: getUdsColors().udsNeutral800,
        icon: "splitted",
      };
    const accountType = Object.values(AccountType).find(
      (at) => at.id === category()?.idAccountType,
    );

    if (accountType) {
      return {
        color: accountType?.color ?? primaryColors.primary500,
        icon: accountType?.icon,
      };
    }

    return {
      color: primaryColors.primary500,
      icon: "undefined",
    };
  };

  // Third Party
  const thirdParty = (): FormattedThirdParty | null => {
    const matchingThirdParty = thirdPartyStore.getMatchingThirdParty({
      idThirdParty: transaction?.entries?.[0]?.idThirdParty ?? null,
      idAccount: transaction?.entries?.[0]?.idAccount ?? null,
    });
    return matchingThirdParty ?? null;
  };

  // Actions

  function refresh(newObject: TransactionType, shouldGlobalRefresh: boolean): void {
    // Bank
    if (bankStore.transactions) {
      const index = bankStore.transactions.findIndex((tr) => tr.id === newObject.id);
      if (index >= 0) {
        bankStore.transactions[index] = {
          ...newObject,
          isLoading: false,
        } as ReadFormattedAccountingTransaction & Loadable;
        if (shouldGlobalRefresh) unlockedStore.fetchBankTransactions(usersStore.currentSocietyId!);
      }
    }

    // Sales
    if (salesStore.items) {
      const indexSales = salesStore.items.findIndex((tr) => tr.id === newObject.id);
      if (indexSales >= 0) {
        salesStore.items![indexSales] = {
          ...newObject,
          isLoading: false,
        } as ReadAccrualInvoice & Loadable;
      }
    }

    // Purchase
    if (societyStore.isAccruals && purchasesStore.items) {
      const index = purchasesStore.items.findIndex((tr) => tr.id === newObject.id);
      if (index >= 0) {
        purchasesStore.items[index] = {
          ...newObject,
          isLoading: false,
        } as ReadFormattedAccountingTransaction & Loadable;
        if (shouldGlobalRefresh) unlockedStore.fetchPurchaseDocuments(usersStore.currentSocietyId!);
      }
    }

    // Annotate after import
    if (annotateStore.importedTransactions) {
      const index = annotateStore.importedTransactions.findIndex((tr) => tr.id === newObject.id);
      if (index >= 0) {
        annotateStore.importedTransactions[index] = {
          ...newObject,
        } as ReadFormattedAccountingTransaction;
      }
    }
  }

  async function annotate(params: {
    payload: UpdateAccountingTransaction;
    dontWaitForApiResult?: boolean;
    shouldImplementErrors?: boolean;
    shoudlRefresh?: boolean;
    refreshList?: string;
  }): Promise<TransactionType> {
    try {
      isLoading.value = true;
      const {
        payload,
        dontWaitForApiResult = false,
        shouldImplementErrors = false,
        shoudlRefresh = true,
        refreshList,
      } = params;
      const _payload = params.payload;

      if (isNotDefined(payload.isAsset)) {
        _payload.isAsset = transaction.isAsset;
      }
      if (isNotDefined(payload.entries)) {
        _payload.entries = transaction.entries;
      }
      let result = { ...transaction, ...payload };
      if (dontWaitForApiResult) {
        $silex()
          .transaction.annotate(transaction.id as number, payload)
          .then(() => {
            $notifier().open({
              content: "Annotation enregistrée",
              type: "success",
            });
          })
          .catch((error) => {
            $notifier().open({
              content: apiErrorToString(error),
              type: "error",
            });
            if (shouldImplementErrors) {
              commonStore.erroredItemIds.push(transaction.id ?? 0);
            }
            result = { ...transaction };
            refresh(result, shoudlRefresh);
          });
        refresh(result, shoudlRefresh);
      } else {
        await $silex()
          .transaction.annotate(transaction.id as number, payload)
          .then((res) => {
            result = res;
            if (!shouldImplementErrors) {
              $notifier().open({
                content: "Annotation enregistrée",
                type: "success",
              });
            }
          })
          .catch((error) => {
            result = { ...transaction };
            if (shouldImplementErrors) {
              commonStore.erroredItemIds.push(transaction.id ?? 0);
            } else {
              $notifier().open({
                content: apiErrorToString(error),
                type: "error",
              });
            }
          });

        if (shoudlRefresh && refreshList === "immobilizations") {
          immoStore.fetchImmobilizations(usersStore.currentSocietyId!);
        }
        refresh(result, shoudlRefresh);
      }

      isLoading.value = false;
      return result;
    } catch (error) {
      $notifier().open({
        content: apiErrorToString(error),
        type: "error",
      });
      isLoading.value = false;
      return transaction;
    }
  }

  async function update(payload: Partial<IAccountingTransaction>): Promise<TransactionType> {
    try {
      isLoading.value = true;
      const result = await $silex().transaction.update(transaction.id as number, payload);
      isLoading.value = false;
      return result;
    } catch {
      return transaction;
    }
  }

  function checkErrors(localEntries: FormEntry[]) {
    const entryErrors: AnnotationError[] = [];

    // Gestion des erreurs de split
    if (localEntries.length > 1) {
      const entryAmounts: number[] = localEntries.map((s) => s.amountAllTaxIncluded ?? 0);
      const sumOfEntryAmounts = entryAmounts.reduce((a, b) => a + b, 0);

      const sum = roundAtXDecimals(sumOfEntryAmounts);
      const isTheSumOfEntryAmountsValid = sum > 0;

      if (!isTheSumOfEntryAmountsValid) {
        entryErrors.push({
          id: transaction.id!,
          type: AnnotationErrorType.AMOUNT_ALL_TAX_INCLUDED,
          message: `La somme des divisions (${roundAtXDecimals(sum)}) doit être supérieure à 0`,
        });
      }

      for (const index in localEntries) {
        const entry = localEntries[index];
        const { idAccount, vat, amountAllTaxIncluded } = entry;
        if (!isDefined(amountAllTaxIncluded) || amountAllTaxIncluded === 0) {
          entryErrors.push({
            id: transaction.id!,
            index: +index,
            type: AnnotationErrorType.AMOUNT_ALL_TAX_INCLUDED,
            message: `Un montant est requis`,
          });
        }
        if (!isDefined(idAccount)) {
          entryErrors.push({
            id: transaction.id!,
            index: +index,
            type: AnnotationErrorType.CATEGORY,
            message: `Une catégorie est requise`,
          });
        }
        if (!societyStore.isNotSubjectToVat && !isDefined(vat)) {
          entryErrors.push({
            id: transaction.id!,
            index: +index,
            type: AnnotationErrorType.VAT,
            message: `Une TVA est requise`,
          });
        }
      }

      const waitingAmount: number = Math.abs(transaction.amount ?? 0);
      const difference = roundAtXDecimals(waitingAmount - sumOfEntryAmounts);

      if (difference !== 0) {
        entryErrors.push({
          id: transaction.id!,
          type: AnnotationErrorType.AMOUNT_ALL_TAX_INCLUDED,
          message: `La somme des divisions (${roundAtXDecimals(
            sumOfEntryAmounts,
          )}€) ne correspond pas au montant total de la transaction (${roundAtXDecimals(
            waitingAmount,
          )}€)`,
        });
      }
    }

    bankStore.errors = entryErrors;
    return entryErrors;
  }

  async function annotateEntry<Property extends keyof FormEntry>(params: {
    index: number;
    payload: {
      property: Property;
      value: FormEntry[Property];
    }[];
    dontWaitForApiResult?: boolean;
    shouldImplementErrors?: boolean;
    shoudlRefresh?: boolean;
    refreshList?: string;
  }): Promise<void> {
    const {
      index,
      payload,
      dontWaitForApiResult = false,
      shouldImplementErrors = false,
      shoudlRefresh = true,
      refreshList,
    } = params;
    const localEntries = cloneDeep(transaction.entries) as FormEntry[];
    payload.forEach((p) => {
      const { property, value } = p;
      if (localEntries.length === 0) localEntries.push({});
      localEntries[index][property] = value;
    });

    if (checkErrors(localEntries).length === 0) {
      await annotate({
        payload: { entries: localEntries },
        dontWaitForApiResult,
        shouldImplementErrors,
        shoudlRefresh,
        refreshList,
      });
    } else {
      refresh({ ...transaction, entries: localEntries }, shoudlRefresh);
    }
  }

  function deleteSplit(index: number) {
    let localEntries = cloneDeep(transaction.entries) as FormEntry[];
    localEntries = localEntries.filter((_l, i) => i !== index);

    if (checkErrors(localEntries).length === 0) {
      annotate({ payload: { entries: localEntries } });
    } else {
      refresh({ ...transaction, entries: localEntries }, true);
    }
  }

  function addSplit() {
    const localEntries = cloneDeep(transaction.entries) as FormEntry[];

    const designation = transaction.label ?? transaction.designation ?? " ";

    const localSplit: FormEntry[] = [
      { ...localEntries?.[0], designation },
      { designation, vat: societyStore.isNotSubjectToVat ? Vat.WITHOUT_TAXE.id : null },
    ];
    refresh({ ...transaction, entries: localSplit }, true);
  }

  return {
    isAsset,
    isAnnotatedAutomatically,
    isProcessedIDocus,
    isCredit,
    isDebit,
    isKilometricAllowance,
    hasCommentary,
    vat,
    isSplitted,
    isLoading,
    categories,
    category,
    categoryMetaData,
    thirdParty,
    isLocked,
    isAnImmobilizationAccount,
    annotate,
    annotateEntry,
    update,
    vats,
    deleteSplit,
    addSplit,
    refresh,
  };
}

export function useAnnotationUtilsComposable() {
  function getVatIdFromFormEntryVat(
    vat: FormEntry["vat"],
    entry?: FormEntry,
  ): number | null | undefined {
    const hasPropertyAmountAllTaxIncludedPurchases = entry?.amountAllTaxIncludedPurchase;
    if (isDefined(hasPropertyAmountAllTaxIncludedPurchases)) {
      return Vat.MARGE.idTva;
    }
    if (typeof vat === "number") {
      return vat;
    }
    if (Array.isArray(vat) && vat.length > 1) {
      return Vat.MANUAL.id;
    }
    if (Array.isArray(vat)) {
      return vat[0].idVat;
    }
    return vat;
  }

  function getVatsForAnnotating({
    intracom,
    margin,
    manual,
  }: {
    intracom: boolean;
    margin: boolean;
    manual: boolean;
  }) {
    return orderBy(
      Object.values(Vat).filter((vat) => {
        if (!margin && vat.id === Vat.MARGE.id) return false;
        if (!manual && vat.id === Vat.MANUAL.id) return false;
        if (!intracom && (vat.isVatIntracom || vat.isVatImportation)) return false;
        return true;
      }),
      ["value"],
      ["desc"],
    );
  }

  function getCategoriesForAnnotating(
    {
      credit = true,
      neutral = true,
      debit = true,
    }: { credit?: boolean; neutral?: boolean; debit?: boolean },
    isLoanCategories?: boolean,
  ): IAccount[] {
    const categoryStore = useCategoryStore();
    const societyStore = useSocietyStore();

    let categories;
    if (isLoanCategories) {
      categories = categoryStore.loanCategories ?? [];
    } else {
      categories = categoryStore.categories ?? [];
    }

    const categoriesNotDeleted = categories?.filter((c) => !isDefined(c.deletedAt)) ?? [];

    const immobilizationAccountsIds =
      categoryStore.immobilizationCategories.map((ac) => ac.id) ?? [];

    const isAccruals = societyStore.isAccruals;

    const categoriesForAnnotating = categoriesNotDeleted.filter((category) => {
      if (category.id) {
        if (immobilizationAccountsIds.includes(category.id) || category.isHidden) {
          return false;
        }

        if ((isAccruals && category.isOnlyTreso) || (!isAccruals && category.isOnlyEnga)) {
          return false;
        }

        if (
          category.accountsSocieties &&
          isArray(category.accountsSocieties) &&
          category.accountsSocieties.length > 0
        ) {
          return !category.accountsSocieties[0].isHidden;
        }
      }

      return true;
    });

    if (isLoanCategories) {
      return categoriesForAnnotating;
    }

    const acceptedNames = [
      ...(credit ? ["Crédit"] : []),
      ...(neutral ? ["Neutre"] : []),
      ...(debit ? ["Débit"] : []),
    ];
    const filteredCategoriesForAnnotating = categoriesForAnnotating.filter((c) => {
      if (c.nature) {
        return acceptedNames.includes(c.nature.name);
      }
      return false;
    });

    return filteredCategoriesForAnnotating;
  }

  return { getVatIdFromFormEntryVat, getVatsForAnnotating, getCategoriesForAnnotating };
}
